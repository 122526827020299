<template>
  <div class="detail__wrap" v-show="visible">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="crumbs__wrap">
      <el-breadcrumb-item :to="{ path: '/company' }">企业管理</el-breadcrumb-item>
      <el-breadcrumb-item ><span @click="toggle(false)" style="font-weight:bold;cursor: pointer;">{{form.data.company_name}}</span></el-breadcrumb-item>
      <el-breadcrumb-item>岗位详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="section shadow">
      <div class="main">
        <el-form
          class="staff-from"
          v-loading="loading"
          element-loading-text="加载中…"
          ref='elFormDom'
          label-width='110px'
          label-position='top'
          :model='form.data'
          size="mini"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="岗位名称">
                <el-input v-model="form.data.title" :readonly="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="岗位类型">
                <el-input v-model="form.data.job_id" :readonly="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所在地区">
                <el-input v-model="form.data.area_str" :readonly="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="工作地址">
                <el-input v-model="form.data.working_address" :readonly="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="详细地址">
                <el-input v-model="form.data.address" :readonly="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="薪资待遇">
                <el-input v-model="form.data.salary_str" :readonly="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="学历要求">
                <el-input v-model="form.data.education" :readonly="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="经验要求">
                <el-input v-model="form.data.work_age_str" :readonly="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="职业类型">
                <el-select v-model="form.data.type" :disabled="true">
                  <el-option
                    v-for="item in typeOpt"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="福利待遇">
                <el-tag
                  style="margin-right:5px"
                  v-for="item in form.data.welfare"
                  :key="item"
                  effect="dark">
                  {{ item }}
                </el-tag>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="工作职责">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 8}"
                  v-model="form.data.duty"
                  :readonly="true">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="任职要求">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 8}"
                  v-model="form.data.demand"
                  :readonly="true">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'QuartersDetail',
    data() {
      return {
        visible: false,
        loading: false,
        reportType: 1,
        form: {
          data: {
            image: [],
          }
        },
        typeOpt: [
          {
            value: 1,
            label: '全职'
          },
           {
            value: 2,
            label: '兼职'
          }
        ]
      }
    },
    created() {
      this.getDetail();
    },
    methods: {
      getDetail(row) {
        if(!!row) {
          this.reportType = row.type;
          this.$http.get('/admin/company/jobInfo', {params:{id: row.id}}).then(res => {
            this.form.data = res.data
            this.form.data.area_str = res.data.province + res.data.city + res.data.area
          })
        }
      },
      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
    }
  }
</script>
<style scoped lang="scss">
.detail__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #f5f5f5;
  padding: 20px;
  z-index: 9;
  overflow-y: scroll;

  .crumbs__wrap{
    margin-bottom: 20px;
  }

  .main {
    width: 800px;
    background-color: #fff;
    padding: 20px;
  }
}

</style>