<template>
  <div class="proposal__wrap">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="crumbs__wrap">
      <el-breadcrumb-item :to="{ path: '/company' }">企业管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.companyName}}</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 表格 -->
    <div class="table__wrap">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="field"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showOverflowTooltip="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <template v-slot:image="{row}">
          <el-image 
          style="width: 100px; height: 50px"
          :src="row.image" 
          :preview-src-list="[row.image]"
          fit="cover">
        </el-image>
        </template>
        <template v-slot:action="{row}">
          <el-button type="text" icon="el-icon-view" @click="showDetail(row)">查看详情</el-button>
        </template>
      </VTable>
    </div>

    <detail ref="detail"></detail>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Detail from './components/Detail.vue'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'FacultyList',
  mixins:[mixinTable],
  components: {
    VTable,
    Detail
  },
  data() {
    return {
      field: [
        { name: "title", label: "岗位名称", hidden: false },
        { name: "salary", label: "薪资待遇", hidden: false },
        { name: "education", label: "学历要求", hidden: false },
        { name: "num", label: "投递人数", hidden: false },
        { name: "update_time", label: "更新时间", hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "160", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          name: "",
          company_id: sessionStorage.getItem('companyId'),
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      companyName: this.$route.query.companyName,
      
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/company/jobList', { params: this.table.params }).then(res => {
        if(res.code === 1) {
           this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    pushPage(row) {
      sessionStorage.setItem('facultyId', row.id)
      this.$router.push({
        path: '/major',
        query: {
          facultyId: row.id,
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
  .proposal__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    position: relative;

    .filter__wrap {
      padding: 10px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }

    .table__wrap {
      flex: 1;
      padding: 0;
      box-sizing: border-box;
    }

    .crumbs__wrap {
      margin-bottom: 20px;
    }
  }
</style>